import React from 'react';
import styles from "./InvestmentSuccess.module.scss";

interface InvestmentSuccessProps {

}

export const InvestmentSuccess: React.FC<InvestmentSuccessProps> = ({ }) => {
	return (
		<div className={`container ${styles.container}`}>
			<p>
				Potwierdzamy, że dodaliśmy Cię do listy uczestników i uczestniczek naszego Webinaru 💪
			</p>
		</div>
	)
}