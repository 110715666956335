import React from 'react';
import { Helmet } from "react-helmet";
import { Footer } from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header/Header";
import { InvestmentSuccess } from '../../../components/Investment/InvestmentSuccess/InvestmentSuccess';
import { useDefaultFooterData } from '../../../hooks/useDefaultFooterData';

interface SuccessProps {

}

const Success: React.FC<SuccessProps> = ({ }) => {
	const { socials } = useDefaultFooterData();

	return (
		<>
			<Helmet>
				<title>Wizyter.pl</title>
			</Helmet>
			<div>
				<Header withRegisterButton={false} />
				<InvestmentSuccess />
				<Footer socials={socials} />
			</div>
		</>
	)
}

export default Success;