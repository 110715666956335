import { graphql, useStaticQuery } from "gatsby";
import { ILP } from "../types";

export const useDefaultFooterData = () => {
	const data: {
		allDataJson: {
			nodes: Array<{
				defaultLp: Pick<ILP, 'socials'>
			}>
		}
	} = useStaticQuery(graphql`
    query {
  allDataJson {
    nodes {
      defaultLp {
        socials {
          youtube
          facebook
          linkedIn
        }
      }
    }
  }
}
  `);

	return {
		socials: data.allDataJson.nodes[0].defaultLp.socials
	}
}